import React from "react";
import Page from "~/templates/Page";

const data = {
  page: {
    metadata: {
      title: "DMI Platform and marketplace",
      description: {
        internal: {
          content:
            "Powered by our proprietary Blink DMI® Cube parametric interface and design tool, the Blink DMI® platform we are creating connects and coordinates all members of the ecosystem throughout a project.",
        },
      },
      image: null,
    },
    sections: [
      {
        __typename: "ContentfulTextAndChecklistSection",
        heading: "Blink DMI® Platform and Marketplace",
        upperContent: {
          raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Powered by our proprietary Blink DMI","nodeType":"text"},{"data":{},"marks":[],"value":"®","nodeType":"text"},{"data":{},"marks":[],"value":" Cube parametric interface and design tool, the Blink DMI","nodeType":"text"},{"data":{},"marks":[],"value":"®","nodeType":"text"},{"data":{},"marks":[],"value":" platform we are creating connects and coordinates all members of the ecosystem throughout a project. The platform serves as a single source of truth throughout development and into the operational life of building, with a digital twin remaining the source of information for smart operation.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
        },
        checklistItems: [
          "Single source of truth with a digital twin​",
          "Enables complete dimensional accuracy​",
          "Customised components for any design​",
          "Open manufacturer marketplace​",
        ],
        lowerContent: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"Our Blink DMI® Cube enables the design and manufacture of the kit of customised parametric components that are made available as a product catalogue for use by our ecosystem. These components include structural elements such as the post-tensioned steel Blink Backbone® and non-structural elements such as façades, walls, windows and stairs.​","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"The platform is open to accredited Blink DMI® partners to maximise design and structural options, achieve scale and geographic distribution, and to enable manufacturers to register and earn from their own intellectual property.​","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"​Component transactions are performed on an open marketplace. Once accredited anyone can participate and sell directly to the market.​","marks":[],"data":{}}]}]}',
        },
        id: "aeeb23e8-8c35-5cde-b20f-9f0dcd4dfd2b",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "DMI Platform",
        displayType: "white",
        numberOfColumns: 3,
        headingLogo: {
          file: {
            url: "//images.ctfassets.net/thdo0maiis3p/6ziQq181drzbTW2PlV9Vzu/e4c7cd1333e86b16a8c6f4478c9ea167/Blink-DMI.svg",
          },
        },
        summary: null,
        buttonText: null,
        buttonLink: null,
        cards: [
          {
            id: "c7661432-d5a5-5fbb-85fb-36519f21a65e",
            title: {
              internal: {
                content: "Parametric interface",
              },
            },
            image: {
              title: "Blink DMI Cube logo",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/6WVrVOQVUUgGmr7HvnP317/05a907ffd56056168457f6abf642f0a3/Blink_DMI_Cube.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"PT Blink’s patented Blink DMI™ Cube drives and coordinates the ecosystem with powerful design tools that build a catalogue of components","marks":[],"data":{}}]},{"nodeType":"paragraph","data":{},"content":[{"nodeType":"text","value":"\\n\\n\\n","marks":[],"data":{}}]}]}',
            },
            ctaLink: "/blink-dmi-cube/",
            ctaText: "Learn more",
          },
          {
            id: "9bc295e9-643c-568a-8ecd-ac4e8de6e5fe",
            title: {
              internal: {
                content: "Parametric components",
              },
            },
            image: {
              title: "Parametric Components",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/2IzohuzMxX8rQZiK9lDHww/c8363abc6f292ce8b4f9b54df5c25984/Parametric_Components.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"The catalogue of structural and non-structural components can be configured to achieve any shape building while achieving complete dimensional accuracy.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"\\n\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/components/",
            ctaText: "Learn more",
          },
          {
            id: "dfa1019e-ed66-51cc-9089-f87dae3e3bb3",
            title: {
              internal: {
                content: "Manufacturer marketplace",
              },
            },
            image: {
              title: "Marketplace",
              file: {
                url: "//images.ctfassets.net/thdo0maiis3p/7jsjyhfHfTNhEgbl4gYqm6/47dea56f20a2c0f5352bdab4b2e3ab70/Manufacturer_Marketplace.svg",
              },
              gatsbyImageData: null,
            },
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"An open marketplace enables direct dealing between buyers and sellers for transparent chain of custody and reduced layers of margin.","nodeType":"text"}],"nodeType":"paragraph"},{"data":{},"content":[{"data":{},"marks":[],"value":"\\n\\n\\n","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/marketplace/",
            ctaText: "Learn more",
          },
        ],
        id: "a37a2dd1-7be2-549b-a98a-69e95eee6cb2",
      },
      {
        __typename: "ContentfulTextAndCardsSection",
        title: "Get Started",
        displayType: "blue",
        numberOfColumns: 2,
        headingLogo: null,
        summary: {
          raw: '{"nodeType":"document","data":{},"content":[{"nodeType":"paragraph","content":[{"nodeType":"text","value":"Let’s talk! Contact us with general enquiries or register your specific interest below.","marks":[],"data":{}}],"data":{}}]}',
        },
        buttonText: "Contact Us",
        buttonLink: "/contact/",
        cards: [
          {
            id: "99314b58-f0a4-5082-a598-4a304eb53733",
            title: {
              internal: {
                content: "FREE consultation -\nProperty Owners & Developers",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Receive a free initial project assessment and understand the likely benefits of using Blink DMI®.","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/book-consultation/",
            ctaText: "Book free consult",
          },
          {
            id: "13f6c73d-182e-58b1-83ad-3090fb55944b",
            title: {
              internal: {
                content: "Designers, Manufacturers and Integrators (builders)",
              },
            },
            image: null,
            content: {
              raw: '{"data":{},"content":[{"data":{},"content":[{"data":{},"marks":[],"value":"Join to access projects and move up the value chain.​","nodeType":"text"}],"nodeType":"paragraph"}],"nodeType":"document"}',
            },
            ctaLink: "/register/",
            ctaText: "Register to join",
          },
        ],
        id: "e2b5a91c-da91-57d0-b2ac-0967dc096306",
      },
    ],
  },
};

function DmiPlatform() {
  return <Page data={data} />;
}

export default DmiPlatform;
